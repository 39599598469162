const getAllQueryParameters = () => {
  if (typeof window !== 'undefined' && 'URLSearchParams' in window) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  }

  return null;
};

export default getAllQueryParameters;
