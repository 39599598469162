import LinkCard from '../cards/LinkCard';

type Dealer = {
  _id: string;
  title: string;
  addressInfoList?: { address: string; postCode: string; postLocation: string }[];
  href?: string;
  isRepairCentre: boolean;
};

interface TablistProps {
  dealers: Dealer[];
}

function ListView({ dealers }: TablistProps) {
  return (
    <section className="relative grid gap-5 lg:grid-cols-2">
      {Array.isArray(dealers) &&
        dealers.map((val) => {
          return <DealerLinkBox key={val._id} dealer={val} />;
        })}
    </section>
  );
}

function DealerLinkBox({ dealer }: { dealer: Dealer }) {
  if (!dealer.addressInfoList || dealer.addressInfoList.length === 0) return null;

  const { title, addressInfoList, href } = dealer;
  const fullAddress = `${addressInfoList[0].address}, ${addressInfoList[0].postCode} ${addressInfoList[0].postLocation}`;
  return <LinkCard href={href || '/'} title={title} description={fullAddress} color="beige-dark" />;
}

export default ListView;
