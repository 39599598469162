import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export enum BUTTON_GROUP {
  ALL = 'Alle',
  DEALER = 'Forhandler',
  REPAIR_CENTRE = 'Skadesenter',
}

function ButtonGroup() {
  const router = useRouter();
  const [selectedButton, setSelectedButton] = useState(BUTTON_GROUP.ALL);

  useEffect(() => {
    if (router.query.c) {
      setSelectedButton(router.query.c as BUTTON_GROUP);
    }
  }, [router.query.c]);

  const handleButtonChange = (buttonText: BUTTON_GROUP) => {
    setSelectedButton(buttonText);
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          c: buttonText,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <div className="flex items-center justify-center max-md:w-full" role="group">
      <Button
        selectedButton={selectedButton}
        handleButtonChange={handleButtonChange}
        value={BUTTON_GROUP.ALL}
      />
      <Button
        selectedButton={selectedButton}
        handleButtonChange={handleButtonChange}
        value={BUTTON_GROUP.DEALER}
      />
      <Button
        selectedButton={selectedButton}
        handleButtonChange={handleButtonChange}
        value={BUTTON_GROUP.REPAIR_CENTRE}
      />
    </div>
  );
}

function Button({
  selectedButton,
  handleButtonChange,
  value,
}: {
  selectedButton: BUTTON_GROUP;
  handleButtonChange: (buttonText: BUTTON_GROUP) => void;
  value: BUTTON_GROUP;
}) {
  return (
    <button
      type="button"
      onClick={() => handleButtonChange(value)}
      className={`hover:bg-background grow rounded-sm rounded-t-md  border-b-2 px-4 py-2 text-center text-base font-bold duration-300 active:bg-[#ddd9d3] md:px-8 md:text-lg md:tracking-wide ${
        selectedButton === value
          ? 'text-tertiary-variant border-tertiary-variant'
          : 'hover:border-primary border-transparent'
      }

      `}
    >
      {value}
    </button>
  );
}

export default ButtonGroup;
